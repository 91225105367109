<template>
<div>
  <div class="">
    <Navbar></Navbar>
  </div>
  <v-layout class="pt-2"  wrap justify-space-around>
    <v-flex xs12 sm9 >
      <v-card>
        <v-card-title>
          Payments to you too
          <v-spacer></v-spacer>
          <v-text-field
          v-model="search"
          append-icon="search"
          label="Search"
          single-line
          hide-details
          ></v-text-field>
        </v-card-title>
        <v-data-table
        :rows-per-page-items='[25,10,5,{"text":"$vuetify.dataIterator.rowsPerPageAll","value":-1}]'
        :headers="headers"
        :items="transfs"
        :search="search"
        item-key="ref"
        :pagination.sync="pagination"
        >
        <template slot="items" slot-scope="props">
          <tr @click="props.expanded = !props.expanded">
            <td class="">{{ props.item.dateSent | dateFormat}}</td>
            <td class="">{{ props.item.ref }}</td>
            <td class="">{{props.item.total}} TWD</td>
            <td class="">{{props.item.comment}}</td>
            <td class="">
              <v-layout  wrap v-for="(el,i) in props.item.items" :key="i">
                {{el.comment}}
              </v-layout>
            </td>
          </tr>
        </template>
        <template slot="expand" slot-scope="props">
          <v-data-table
            :rows-per-page-items='[25,10,5,{"text":"$vuetify.dataIterator.rowsPerPageAll","value":-1}]'
            :items="props.item.items"
            :headers="headers2"
          >
            <template slot="items" slot-scope="props">

              <td class="">{{props.item.roomNumber}}</td>
              <td class="" > <span v-if="props.item.amount==undefined">{{props.item.price }} - {{props.item.margin}}</span><span v-else>{{props.item.amount}}</span> TWD </td>
              <td class="">{{props.item.comment}}</td>
            </template>
          </v-data-table>
          </template>

        <v-alert slot="no-results" :value="true" color="error" icon="warning">
          Your search for "{{ search }}" found no results.
        </v-alert>
    </v-data-table>
  </v-card>

    </v-flex>
  </v-layout>
</div>
</template>

<script>
import firebase from '../../FirebaseApp'
import moment from 'moment'
import {
  mapGetters
} from 'vuex'
import * as types from '../../store/types'

export default {
  name: 'Payments',
  data: () => ({
    headers: [
      { text: 'Date', value: 'dateSent' },
      { text: 'Reference', value: 'ref' },
      { text: 'Total transferred', value: 'total' },
      { text: 'General Comment', value: 'comment' },
      { text: 'Details (click for more)', sortable:false },
    ],
    headers2: [
      { text: 'Room', value: 'roomNumber' },
      { text: 'price', value: 'price' },
      { text: 'comment', value: 'comment' },
    ],
    pagination:{descending:true},
    search: null,
    searched: [],
  }),
  firebase() {
    return {
      transfs: {
        source:firebase.database().ref('payments').orderByChild("landlordId").equalTo(this.tUserId),
      }
    }
  },
  methods: {
  },
  filters: {
    dateFormat: function(t) {
      if (t){
        return moment(t).format('ll')
      } else {
        return "Nothing to show"
      }
    },
  },
  computed: {
    ...mapGetters({
      user: types.GET_USER
    }),
    tUserId(){
      if (this.$route.query.id!=undefined && this.user.roleValue>70){
        return this.$route.query.id
      } else {
        return this.user.id
      }
    }
  },
  created() {
  }
}
</script>

<style lang="scss" scoped>
.md-field {
    max-width: 300px;
}
</style>
